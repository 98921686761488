import React from "react"
import { JesLayout } from "../../components/jes-layout"
import {
  JesRow,
  HalfCol,
  FullCol,
  LargeCol,
  SmallCol,
} from "../../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "antd"
import { SubHeader } from "../../components/subheader"
import {
  StandardParagraph,
  SecondaryHeading,
  PrimaryHeading,
  Legend,
  BLOCKS_SPACING,
  Bold,
} from "../../components/blocks"
import { useMediaQuerySafe } from "../../components/use-mq-safe"
import { useI18next, Trans } from "gatsby-plugin-react-i18next"

interface DescriptionBlockProps {
  title?: string
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
}

const DescriptionBlock: React.FC<DescriptionBlockProps> = ({
  title,
  style,
  titleStyle,
  children,
}) => (
  <Row style={{ padding: "30px 0px", ...style }}>
    <Col span={24}>
      {title && <h1 style={titleStyle}>{title}</h1>}
      <div>{children}</div>
    </Col>
  </Row>
)
const ProjektePage = ({ data }) => {
  const [isMobile, setisMobile] = React.useState(false)
  const colSize = useMediaQuerySafe()

  React.useEffect(() => {
    setisMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  const { t } = useI18next()

  return (
    <JesLayout
      title={`Kuk.art | ${t("Projekte")}`}
      beforeContent={<SubHeader />}
    >
      {/* 3rd ROW (JUGEND + Kunst und kultur) */}
      <JesRow align="top" noGutter noPadding>
        <FullCol>
          <Img
            className="kukLogo"
            style={{ maxWidth: "90%", width: "400px", margin: "auto" }}
            fluid={data.kukLogo.childImageSharp.fluid}
          />
        </FullCol>
      </JesRow>
      <JesRow
        style={{
          padding: "90px 0 40px 0",
        }}
        align="middle"
        noGutter
        noPadding
      >
        <HalfCol
          style={{
            paddingRight: "15px",
            marginBottom: isMobile ? BLOCKS_SPACING : 0,
          }}
          noPaddingMobile
        >
          <StandardParagraph
            style={{
              marginLeft: "auto",
              maxWidth: isMobile ? "auto" : "440px",
              width: "100%",
              marginBottom: 0,
            }}
          >
            <Trans i18nKey="kuk.first_description_block">
              Die Website{" "}
              <Link to="https://kuk.art" target="_blank">
                www.kuk.art
              </Link>{" "}
              ist ein interaktives Webprojekt, das die Kunstgeschichte der
              Künstlerkolonie Holzhausen am Ammersee sichtbar und erlebbar
              macht.
            </Trans>
          </StandardParagraph>
        </HalfCol>
      </JesRow>
      <JesRow align="top" noGutter noPadding>
        <HalfCol style={{ paddingRight: "15px" }} noPaddingMobile>
          <Img
            style={{ marginLeft: "auto", maxWidth: "440px" }}
            fluid={data.jugend.childImageSharp.fluid}
          />
        </HalfCol>
        <HalfCol style={{ paddingLeft: "15px" }} noPaddingMobile>
          <JesRow noGutter noPadding>
            <LargeCol>
              <PrimaryHeading
                style={{
                  marginBottom: 0,
                  marginTop: isMobile ? BLOCKS_SPACING : 0,
                }}
              >
                <Trans i18nKey="kuk.first_block_heading">
                  Kunst- und Kulturgeschichten in Holzhausen am Ammersee
                </Trans>
              </PrimaryHeading>
              <StandardParagraph
                style={{
                  marginBottom: 0,
                  padding: ` ${BLOCKS_SPACING} 0`,
                }}
              >
                <Trans i18nKey="kuk.second_paragraph">
                  Es sind die Geschichten, die bewegen und uns Persönlichkeiten
                  näher bringen – vor allem im Kontext der Kunst- und
                  Zeitgeschichte. Genau diese wollen wir herausarbeiten und
                  erlebbar machen. So bleibt die Geschichte lebendig.
                </Trans>
              </StandardParagraph>
            </LargeCol>
          </JesRow>
          <Img fluid={data.holzausen.childImageSharp.fluid} />
        </HalfCol>
      </JesRow>
      {/* 7th row (KUK showcase) */}
      <JesRow
        noGutter
        noPadding
        align="top"
        style={{ marginBottom: BLOCKS_SPACING, marginTop: "50px" }}
      >
        <LargeCol lg={18} style={{ paddingRight: isMobile ? 0 : "15px" }}>
          <Img fluid={data.kukStartPage.childImageSharp.fluid} />
          <Legend style={{ padding: "10px 0", textAlign: "right" }}>
            (Screenshot www.kuk.art)
          </Legend>
        </LargeCol>
        <SmallCol lg={6} style={{ paddingLeft: "15px" }} noPaddingMobile>
          <StandardParagraph>
            <Trans i18nKey="kuk.website_description_1">
              Auf der Website{" "}
              <Link to="https://kuk.art" target="_blank">
                www.kuk.art
              </Link>{" "}
              präsentieren wir nach und nach die rund 50 Künstler und
              Künstlerinnen, die in der Künstlerkolonie Holzhausen am Ammersee
              seit 1890 gelebt und gearbeitet haben.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="kuk.website_description_2">
              Die Präsentation der Künstlerpersönlichkeiten, ihrer Werke, ihrer
              Geschichten und die Verbundenheit zu dem Ort Holzhausen sind in
              vielfältiger Art dargestellt. Mit Bild-, Text- und Audiodateien
              erwecken wir das Leben und das Werk der Künstler und
              Künstlerinnen.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="kuk.website_description_3">
              In einem ersten Schritt liegt der Fokus auf denjenigen Künstlern
              und Künstlerinnen, die zu Beginn des 20. Jahrhunderts in
              Holzhausen tätig waren, und bei denen das Urheberrecht an ihren
              Werken entweder abgelaufen ist oder die Nachfahren einer
              Veröffentlichung zugestimmt haben.
            </Trans>
          </StandardParagraph>
        </SmallCol>
      </JesRow>
      <JesRow
        align="top"
        noPadding
        noGutter
        rowStyle={{
          justifyContent: "flex-end",
          flexDirection: isMobile ? "column-reverse" : "row",
        }}
      >
        <SmallCol style={{ paddingRight: "15px" }} noPaddingMobile>
          <StandardParagraph>
            <Trans i18nKey="kuk.platform_description_1">
              Die Plattform wird kontinuierlich ausgebaut und später soll das
              Augenmerk auch verstärkt auf das zeitgenössische Kunstschaffen
              gelenkt werden.
            </Trans>
          </StandardParagraph>
          <StandardParagraph>
            <Trans i18nKey="kuk.platform_description_2">
              Die Kunsthistorikerinnen und Zeithistoriker in unserem Team
              garantieren professionelle Arbeit und verlässliche Informationen.
              Für eventuell noch erforderliche Korrekturen und Ergänzungen sind
              wir dankbar.
            </Trans>
          </StandardParagraph>
          <div>
            <PrimaryHeading style={{ marginTop: "50px" }}>
              <Trans>Wir suchen</Trans>
            </PrimaryHeading>
            <SecondaryHeading style={{ color: "inherit" }}>
              <Trans>Kunstwerke</Trans>:
            </SecondaryHeading>
            <StandardParagraph>
              <Trans i18nKey="kuk.kunstwerke_block_description">
                Zur Erstellung von Werkverzeichnissen möchten wir in
                Privatbesitz befindliche Bilder, Zeichnungen, Fotos und
                Plastiken der Holzhauser Künstler dokumentieren. Bitte nehmen
                Sie mit uns Kontakt auf, wenn Sie Werke von Holzhauser Künstlern
                in Ihrem Besitz haben. Diskretion sichern wir Ihnen zu.
              </Trans>
            </StandardParagraph>
            <SecondaryHeading style={{ color: "inherit", marginTop: "20px" }}>
              <Trans>Holzhauser G’schicht’n</Trans>:
            </SecondaryHeading>
            <StandardParagraph>
              <Trans i18nKey="kuk.gschicht_block_description">
                Wir suchen Holzhauser G‘schicht’n in Zusammenhang mit den
                Künstlern, ihren Häusern oder ganz allgemein aus unserem Ort.
                Wir freuen uns sehr, wenn Sie etwas beitragen können. Bitte
                melden Sie sich bei uns.
              </Trans>
            </StandardParagraph>
          </div>
        </SmallCol>
        <HalfCol style={{ paddingLeft: "15px" }} noPaddingMobile>
          <Img fluid={data.kukKunstUndKultur.childImageSharp.fluid} />
          <Legend style={{ padding: "10px 0", textAlign: "right" }}>
            (Screenshot www.kuk.art)
          </Legend>

          <div>
            <PrimaryHeading>
              <Trans>Perspektive</Trans>
            </PrimaryHeading>
            <StandardParagraph>
              <Trans i18nKey="kuk.prespektive_content">
                Das Projekt kuk.art könnte als Blaupause für andere Orte rund um
                den Ammersee und darüber hinaus genutzt werden und so im Laufe
                der Jahre zu einer zeitgemäßen Darstellung der Region und ihrer
                Persönlichkeiten beitragen.
              </Trans>
            </StandardParagraph>
          </div>
        </HalfCol>
      </JesRow>
    </JesLayout>
  )
}

export default ProjektePage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jochenUndErika: file(relativePath: { eq: "jochenunderikaseifert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jesLogoTop: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 375) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukLogo: file(relativePath: { eq: "kuk_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukStartPage: file(relativePath: { eq: "kuk_design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    holzausen: file(relativePath: { eq: "holzhausen_postkarte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukShowcaseErler: file(relativePath: { eq: "kukart-showcase-erler.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukArtists: file(relativePath: { eq: "kuk_artists_page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukKunstUndKultur: file(relativePath: { eq: "kuk-kunstundkultur.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jugend: file(relativePath: { eq: "jugend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
