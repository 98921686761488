import React from "react"
import { JesRow, FullCol } from "./layout"
import { Link } from "gatsby"
import { NavigationLink } from "./blocks"

export const SubHeader = () => {
  return (
    <JesRow>
      <FullCol>
        <JesRow noPadding noGutter>
          <div
            style={{
              padding: "15px 0",
              display: "flex",
              flexDirection: "row",
            }}
            className="header-links-wrapper"
          >
            <NavigationLink noMargin style={{ marginRight: "15px" }} to="/projekte/kuk">
              KUK.ART
            </NavigationLink>
            <NavigationLink noMargin to="/projekte/euro-art">EUROART</NavigationLink>
          </div>
        </JesRow>
      </FullCol>
    </JesRow>
  )
}
